var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ul",
    { staticClass: "vx-timeline" },
    _vm._l(_vm.data, function (item) {
      return _c("li", { key: item.title }, [
        _c(
          "div",
          { staticClass: "timeline-icon", class: `bg-${item.color}` },
          [
            _c("feather-icon", {
              attrs: {
                icon: item.icon,
                svgClasses: "text-white stroke-current w-5 h-5",
              },
            }),
          ],
          1
        ),
        _c("div", { staticClass: "timeline-info" }, [
          _c("p", { staticClass: "font-semibold" }, [
            _vm._v(_vm._s(item.title)),
          ]),
          _c("span", { staticClass: "activity-desc" }, [
            _vm._v(_vm._s(item.desc)),
          ]),
        ]),
        _c("small", { staticClass: "text-grey activity-e-time" }, [
          _vm._v(_vm._s(item.time)),
        ]),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }